/* main entry point */
console.log('in main');

var test = require('./testmod'),
    gallery = require('./gallery2');
    require('./headshot');
    require('./common');

test();
gallery();
