var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    modal = require('./modal.js'),
    images = require('./images2.js'),
    thumbs = require('./thumbs.js'),
    animateGallery = require('./animate_gallery.js'),
    Handlebars = require('handlebars'),
    imagesHeaderTemplate = require('./templates/gallery.images.header.hbs'),
    clickHandler = require('./clickhandler'),
    blueimpGallery = require('blueimp-gallery');

module.exports = function() {
    console.log('gallery js init');
};

var leftHeaderSelector = '#header_left',
    leftHeader = $(leftHeaderSelector),
    rightHeaderSelector = '#header_right',
    rightHeader = $(rightHeaderSelector),
    middleHeaderSelector = '#header_middle',
    middleHeader = $(middleHeaderSelector),
    thumbnailContainer = $('.gallery-thumbnails'),
    head2 = $('.place2'),
    head3 = $('.place3'),
    head4 = $('.place4'),
    isMobile = !leftHeader.is(':visible'); // mobile view if not visible

// initialize thumbnails
thumbs(thumbnailContainer);

var layout0 = function(data) {
    var left = imagesHeaderTemplate({images: [data.images[0]]}),
        right = imagesHeaderTemplate({images: [data.images[1]]}),
        place2 = imagesHeaderTemplate({images: [data.images[4]]}),
        place3 = imagesHeaderTemplate({images: [data.images[2]]}),
        place4 = imagesHeaderTemplate({images: [data.images[3]]});

    leftHeader.html(left);
    leftHeader.find('img').one('load', function() {
        $('.gallery-head').height(leftHeader.height());
        $(document).trigger('lab:gallery:header:sized');
    });
    rightHeader.html(right);
    head2.html(place2);
    head3.html(place3);
    head4.html(place4);
};

var layout1 = function(data) {
    var left = imagesHeaderTemplate({images: [data.images[0]]}),
        right = imagesHeaderTemplate({images: [data.images[1]]}),
        place2 = imagesHeaderTemplate({images: [data.images[2]]}),
        place3 = imagesHeaderTemplate({images: [data.images[3]]});

    leftHeader.html(left);
    // set the height of the whole header images based on
    // this leftmost image
    leftHeader.find('img').one('load', function() {
        var refHeight = leftHeader.height();
        $('.gallery-head').height(refHeight);
        leftHeader.height(refHeight); // fix this height too
        rightHeader.height(refHeight); // fix this height too

        if ($('.place').length == 4) {
            head2.height(refHeight / 2);
            head3.height(refHeight / 2); // fix this height too
        } else {
            head2.height(refHeight);
        }

        rightHeader.html(right);
        head2.html(place2);
        head3.html(place3);

        $(document).trigger('lab:gallery:header:sized');
    });
};


// when the header images are available, populate the header
$('body').on('lab:gallery:headerimages', function(event, data) {
    //layout0(data)
    layout1(data)
});

// thumbnail click handler
$('body').on('click', '.thumbnail', function(event) {
    event.preventDefault();
    var objects = [],
        obj,
        data,
        clickedSrc = $(this).children('img').attr('src'),
        clickedData; // for telling the viewer which one started with
    $('.thumbnail img').each(function() {
        obj = $(this);
        if (obj.attr('data-url')) {
            data = {url: obj.attr('data-url'), title: obj.attr('data-title')};
            objects.push(data);
            if (obj.attr('src') === clickedSrc) {
                clickedData = data;
            }
        }
    });
    var blueimpgallery = blueimp.Gallery(objects, {
        urlProperty: 'url',
        index: clickedData,
    });
});

// like thumbnail click handler; enlarge helper
$('body').on('click', '.enlarge', function(event) {
    event.preventDefault();
    $(this).closest('div').children('.thumbnail').click();
});

// scroll click handler
$('body').on('click', '#scroll_more', function(event) {
    var that = $(this);
    event.preventDefault();
    $('html,body').animate({
        scrollTop: $(window).scrollTop() + 300
    }, 800, function() {
        that.fadeOut();
    });
});

/*
 * Position the scroll more button
 * after first row of thumbs renders.
 */
$(document).on('lab:gallery:header:sized', function(event) {
    var button, targetOffset,
        target = $('.gallery-thumbnails'),
        firstThumb = target.find('.row:first div:first'),
        offset;
    target.prepend('<span id="scroll_more"></span>');
    button = $('#scroll_more');
    targetOffset = target.offset();
    offset = {
        top: targetOffset.top - button.height(),
        left: targetOffset.left + target.width() - firstThumb.width() / 2 - button.width() / 2
    };
    if ($(window).height() < offset.top) {
        offset.top = $(window).height() - button.height();
        $(window).one('scroll', function(event) {
            $('#scroll_more').click();
        });
    }
    button.offset(offset);
    button.show();
});


(function() {
    var key = $('#gallery_key').val(),
        placeCount = $('.place').length;
    if (key) {
        if (!isMobile) {
            // event lab:headerimages when complete
            images.getGalleryHeader(key, leftHeader.width(), placeCount);
        }
        images.getGalleryImages(key);
    }
})();
