var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

function main() {
    console.log('common loaded');

    // setup any frequently asked questions
    $('.freq-question h5').on('click', function() {
        var openClose = $(this).find('button i'),
            openCloseText = openClose.text(),
            container = $(this).parent();
        console.log(openClose);

        /* + means click to expand */
        if (openClose.hasClass('fa-plus')) {
            openClose.removeClass('fa-plus');
            openClose.addClass('fa-minus');
            container.children('.content').each(function() {
                $(this).show();
            });
        } else if (openClose.hasClass('fa-minus')) {
            openClose.removeClass('fa-minus');
            openClose.addClass('fa-plus');
            container.children('.content').each(function() {
                $(this).hide();
            });
        }
    });

    /*
    * hook up headshot modal control
    */
    (function() {
        $('#headshot_guide_modal').modal({show:false});

        $('#headshot_guide_modal').find('button[type="submit"]').on('click', function(event) {
            event.preventDefault();
            $.post('/give-away', $('#headshot_guide_modal').find('form').serialize(), function(data) {
                if (data.result === 'error') {
                    for(field in data.errors) {
                        $('#headshot_guide_modal form [name="' + field + '"]')
                            .closest('.control-group').addClass('error');
                        $('#headshot_guide_modal form [name="' + field + '"]')
                            .siblings('.help-inline').text(data.errors[field][0]);
                    }
                } else {
                    var _modal = $('#headshot_guide_modal');
                    _modal.find('form').hide();
                    _modal.find('button[type="submit"]').hide();
                    _modal.find('.modal-body').html('<p class="lead">Your guide is on the way!<br>Please check your email.</p>');
                }
            });
        });
    }());

    /*
    * hook up company headshot modal control
    */
    (function() {
        $('#company_headshot_guide_modal').modal({show:false});

        $('#company_headshot_guide_modal').find('button[type="submit"]').on('click', function(event) {
            event.preventDefault();
            $.post('/give-away', $('#company_headshot_guide_modal').find('form').serialize(), function(data) {
                if (data.result === 'error') {
                    for(field in data.errors) {
                        $('#company_headshot_guide_modal form [name="' + field + '"]')
                            .closest('.control-group').addClass('error');
                        $('#company_headshot_guide_modal form [name="' + field + '"]')
                            .siblings('.help-inline').text(data.errors[field][0]);
                    }
                } else {
                    var _modal = $('#company_headshot_guide_modal');
                    _modal.find('form').hide();
                    _modal.find('button[type="submit"]').hide();
                    _modal.find('.modal-body').html('<p class="lead">Your guide is on the way!<br>Please check your email.</p>');
                }
            });
        });
    }());
}
main()

module.exports = {
};
