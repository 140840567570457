/*
 * Suppresses right click in galleries
 * so people can't save images so easily.
 */

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

$('body').on('contextmenu', 'img', function(event) {
        return false;
});
