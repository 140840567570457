var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

/* button listener */
$('.win').on('click', function(event) {
    event.preventDefault();
    $('#book_modal').modal({show: true, keyboard: false});
    $('#book_modal').on('shown.bs.modal', function(event) {
        $('#name').focus();
    });
});

$('#form_submit').on('click', function(event) {
    event.preventDefault();
    var form = $(this).closest('form'),
        name = $('#name').val(),
        email = $('#email').val(),
        data = form.serialize(),
        url = form.attr('action');

    if (!name || !email) {
        $('.error-display').css('visibility', 'visible');
        return;
    }

    var xhr = $.ajax({
        url: url,
        data: data,
        method: 'POST'
    }).done(function(retData) {
        console.log(retData);
        window.location = '/getmyheadshot/thankyou';
    }).fail(function() {
        $('.error-display').css('visibility', 'visible');
    });
});

$('#new_headshot_button').on('click', function(event) {
    event.preventDefault();
    $('#new_headshot_modal').modal({show: true});
});

// position banner image
var positionMainHS = function() {
    var image = $('.showcase'),
        left = $('.hdr .hdr-section').first(),
        right = $('.hdr .hdr-section').last(),
        container = image.parent().parent(),
        offset = container.height() - image.height();
    if (right.height() > left.height() && image.height() < right.height()) {
        console.log('adjusting showcase image');
        left.css('position', 'relative');
        image.css({'position': 'absolute', 'top': offset});
    } else if (image.height() > right.height()) {
        left.css('position', 'static'); // set back if peviously set
        image.css('position', 'static');
    }
}
// run on page load
$('.showcase').on('load', function() {
    positionMainHS();
});
// also run if teh page resizes
$(window).on('resize', positionMainHS);

module.exports = {
};
