var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    galleryData = require('./gallerydata');

var makeRequest = function(url, galleryKey, eventCode, cursor) {
    var requestData = {gallery_key: galleryKey};
    if (cursor !== undefined) {
        requestData['c'] = cursor;
    }
    var $xhr = $.ajax({
        url: url,
        method: 'GET',
        data: requestData
    });
    $xhr.done(function(data) {
        if (url.indexOf('header') != -1) {
            console.log(data);
        }
        $('body').trigger(eventCode, [data]);
        if (data.next_cursor !== undefined) {
            makeRequest(url, galleryKey, eventCode, data.next_cursor);
        }
    });
};

var getGalleryImages = function(galleryKey) {
    var url = '/api/gallery/images',
        eventCode = 'lab:gallery:images';
    makeRequest(url, galleryKey, eventCode);
};

var getGalleryHeader = function(galleryKey, width, placeCount) {
    var url = '/api/gallery/images/header' + '?width=' + width,
        eventCode = 'lab:gallery:headerimages';
    if (placeCount == 3) {
        url = '/api/gallery/images/header/3' + '?width=' + width;
    } else if (galleryData.gallery_type === 'customer') {
        url = '/api/gallery/images/header/customer' + '?width=' + width;
    }
    makeRequest(url, galleryKey, eventCode);
};

module.exports = {
    getGalleryImages: getGalleryImages,
    getGalleryHeader: getGalleryHeader
};
