var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

$('body').on('click', '.modal-content .modal-header .close', function(event) {
    event.preventDefault();
    console.log('closing modal');
    $(this).closest('.modal').modal('hide');
});

module.exports = {
    /* show the loading modal */
    load: function() {
        $('#loading_modal').removeClass('hide');
        $('#loading_modal').modal({show: true});
    },
    show: function(selector) {
        console.log('showing modal ' + selector);
        $(selector).removeClass('hide');
        $(selector).modal('show');
    },
    hide: function(selector) {
        $(selector).modal('hide');
    }
};
