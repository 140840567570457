var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    galleryData = require('./gallerydata');

var leftHeaderSelector = '#header_left',
    leftHeader = $(leftHeaderSelector),
    rightHeaderSelector = '#header_right',
    rightHeader = $(rightHeaderSelector),
    middleHeaderSelector = '#header_middle',
    middleHeader = $(middleHeaderSelector),
    head2 = $('.place2'),
    head3 = $('.place3'),
    head4 = $('.place4'),
    isMobile = !leftHeader.is(':visible'), // mobile view if not visible
    urlRegex = new RegExp('^https?:\\/\\/.*(=.*)$', 'i'),
    places = $('.place'),
    portraits,
    landscapes;

var sizeUrl = function(image) {
    var matched = image.attr('src').match(urlRegex),
        container = $(image.parent()),
        imgData = image.data('replacement'),
        rootUrl = imgData.url,
        width = container.width(),
        height = container.height(),
        imgWidth = imgData.width,
        imgHeight = imgData.height,
        newWidth,
        newHeight,
        widthScale = width / imgWidth,
        heightScale = height / imgHeight,
        dim;
    if (width >= height) { // landscape
        dim = width;
        // since scaled on width, it is wide enough
        // is it tall enough?
        newHeight = Math.round(imgHeight * widthScale);
        if (newHeight < (height - 1)) {  // not tall enough
            dim = Math.round(imgWidth * heightScale);
        }
    } else {               // portrait
        dim = height;
        // since scaled on height, it is tall enough
        // is it wide enough?
        newWidth = Math.round(imgWidth * heightScale);
        if (newWidth < (width - 1)) { // not wide enough
            dim = Math.round(imgHeight * widthScale);
        }
    }
    //return rootUrl + matched[1];
    return rootUrl + '=s' + Math.floor(dim);
};

var iterateThumbs = function() {
    portraits = [];
    landscapes = [];
    var thumbs = $('.gallery-thumbnails .thumbnail img'), // get these dynamically to pick up any new due to 'paging'
        currImages = $('.place img'), // use these to exlude current images
        currUrls = currImages.map(function(index, image) {
            return $(image).attr('src').split('=')[0];
        }).toArray();

    // iterate through thumbnails, determine orientation
    var thumb, w, h, url, obj; 
    for (var i=0, numThumbs=thumbs.length; i < numThumbs; i++) {
        thumb = $(thumbs[i]);
        url = thumb.data('origurl');
        w = thumb.width();
        h = thumb.height();
        if (url && currUrls.indexOf(url) == -1) { // don't reuse same image being replaced
            obj = {url: url, width: thumb.data('width'), height: thumb.data('height')};
            if (w >= h) {
                landscapes.push(obj);
            } else {
                portraits.push(obj);
            }
        }
    }
};

var onFadeOut = function() {
    var image = $(this);
    // change the image
    image.attr('src', sizeUrl(image));
    // fade in when loaded
    image.one('load', function() {
        image.fadeIn(onFadeIn);
    });
};

var onFadeIn = function() {
    setTimeout(animate, 4000);
};

var animate = function() {
    // pick one of the spots at random
    // get a new image to go there
    // animate the image out and new one in
    // animate again some seconds later
    
    var choice = Math.floor(Math.random() * places.length),
        image = $(places[choice]).find('img');

    iterateThumbs();

    var choices = image.width() >= image.height() ? landscapes : portraits,
        replacement = choices[Math.floor(Math.random() * choices.length)];
    if (replacement) {
        // store for retrieval in other function
        image.data('replacement', replacement);
        image.fadeOut(onFadeOut);
    } else { 
        // no replacement found; must mean there are not enough images
        // to cycle through, so just pick another place to animate
        animate();
    }
};

(function() {
    if (!isMobile && galleryData.gallery_type !== 'customer') {
        setTimeout(animate, 4000)
    }
})();
