// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "        <div class=\"col-xs-12 col-sm-2\">\n            <a href=\"#\" class=\"thumbnail\">\n                <img src=\""
    + alias2(alias1((depth0 != null ? depth0.thumb_url : depth0), depth0))
    + "=s"
    + alias2(alias1((depths[1] != null ? depths[1].size : depths[1]), depth0))
    + "\" \n                    data-url=\""
    + alias2(alias1((depth0 != null ? depth0.url : depth0), depth0))
    + "\" \n                    data-title=\""
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "\" \n                    title=\""
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "\" \n                    data-origurl=\""
    + alias2(alias1((depth0 != null ? depth0.thumb_url : depth0), depth0))
    + "\" \n                    data-width=\""
    + alias2(alias1((depth0 != null ? depth0.width : depth0), depth0))
    + "\" \n                    data-height=\""
    + alias2(alias1((depth0 != null ? depth0.height : depth0), depth0))
    + "\">\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.number : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </a>\n        </div>\n";
},"2":function(depth0,helpers,partials,data) {
    return "                    <span class=\"number\">"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.number : depth0), depth0))
    + "</span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div class=\"row\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.images : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});
