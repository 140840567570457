/* Add thumbnail images to gallery */

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    galleryData = require('./gallerydata'),
    imagesThumbsTemplate = require('./templates/gallery.thumbs.hbs'),
    thumbnailsContainer;

var getDesiredWidth = function() {
    // using markup/css to define structure, including width
    // all thrumbs are same dimension, so just get the first one
    return thumbnailsContainer.children('.row').first().children('div').first().width();
};

var addThumbnails = function(images, reset) {
    var desiredWidth = getDesiredWidth(),
        thumbnailHtml;
    if (images.length > 0) {
        thumbnailHtml = imagesThumbsTemplate({images: images, size: desiredWidth});
        if (reset) {
            thumbnailsContainer.html(thumbnailHtml);
        } else {
            thumbnailsContainer.append(thumbnailHtml);
        }
    }
    // if first row in gallery, desiredWidth is not known
    // redo this now that there is dom to inspect
    if (desiredWidth === null) {
        desiredWidth = addThumbnails(images, true);
    }
    return desiredWidth;
};

var closeTo = function(value, target) {
    var eps = 2; // epsilon
    return value <= (target + eps) && value >= (target - eps); 
};

var getImageHeight = function(image) {
    var imageHeight = image.height(),
        imgUrl = image.attr('src'),
        urlHeight = parseInt(imgUrl.split('=s')[1]),
        IEmisreport = 30; // the value IE may misreport
    var delta = imageHeight - urlHeight;
    // the two heights differ and close misreporting height
    if (!closeTo(delta, 0) && closeTo(imageHeight, IEmisreport)) {
        console.log('delta height = ' + delta);
        console.log('used fallback url height');
        return urlHeight;
    } else { // height is ok
        return imageHeight;
    }
};

$('body').on('lab:gallery:images', function(event, data) {
    //console.log(data);
    var desiredWidth = addThumbnails(data.images);
    if (desiredWidth) {
        // size gallery thumbnail div dimensions
        var thisRow = $('.gallery-thumbnails .row').last(), // the last row just laid down
            theseThumbs = thisRow.find('.thumbnail');
        theseThumbs.each(function(index) {
            var thumb = $(this);
            // set the height and width
            thumb.height(desiredWidth).width(desiredWidth);
            // now pad based on actual image dimensions
            var thumbHeight = thumb.height(),
                image = thumb.children('img').first(), // there is only one img per thumb
                imageHeight,
                padding;
            image.on('load', function() { // wait for image to load
                imageHeight = getImageHeight(image);
                if (imageHeight < 0.9 * thumbHeight && !closeTo(imageHeight, 0)) {
                    padding = (thumbHeight - imageHeight) / 2;
                    thumb.css('padding', padding + 'px 0');
                }
            });
        });
        $(document).trigger('lab:gallery:thumbs:rendered');
    }
});

module.exports = function(container) {
    thumbnailsContainer = container;
};
